<template>
  <!-- profile css 사용 -->
  <div class="container profile">

    <!-- 멤버십 가입하기 -->
    <div class="payment-header">
      <template v-if="promoEndDate >= Date.now()">
        <div class="title">헤이비글 멤버십 출시 기념<br>{{promoSalePer}}%할인 (기간 한정)</div>
        <div class="text">프로모션 기간을 놓치지 마세요.<br>지금 가입하고 남들보다<br>더 많은 기회와 노출을 보장 받으세요.</div>
      </template>
      <template v-else>
        <div class="title">멤버십에 가입하고<br>특별한 자격을 얻으세요.</div>
        <div class="text">#바로연락 #지원 무제한 #수수료제로<br>#눈에띄는프로필 #전화의뢰받기</div>
      </template>
    </div>

    <div class="ui-border-line" style="height: 10px ui-mb-6"></div>

    <div class="profile-info">

      <!-- <h4>결제 방법</h4>
      <div class="ui-border-line ui-mt-3 ui-mb-1"></div>
      <div class="info-block">
        <ul>
          <li>신용카드</li>
        </ul>
        <div class="ui-border-line ui-mt-1"></div>
      </div>

      <div class="ui-border-line ui-mb-6" style="height: 10px"></div> -->

      <h4>결제 금액</h4>
      <div class="ui-border-line ui-mt-3 ui-mb-1"></div>
      <div class="info-block">
        <ul>
          <li>멤버십 (월 이용료)</li>
          <li class="float-right" style="text-decoration: line-through;">{{$lib.addComma(promoPrice)}}원</li>
        </ul>
        <div class="ui-border-line ui-mt-1 ui-mb-1"></div>
      </div>
      <!--  -->
      <div class="info-block" v-if="promoSalePer > 0 && promoEndDate >= Date.now()">
        <ul>
          <li>특별 할인 ({{moment(promoEndDate).format('YY.MM.DD')}}까지)</li>
          <li class="float-right">{{promoSalePer}}%</li>
        </ul>
        <div class="ui-border-line ui-mt-1 ui-mb-1"></div>
      </div>
      <!--  -->
      <div class="info-block">
        <ul>
          <li class="color-lavender ui-weight-6">최종 결제 금액</li>
          <li class="color-lavender ui-weight-6 float-right">{{$lib.addComma(billData.billPrice || 0)}}원</li>
        </ul>
        <div class="ui-border-line ui-mt-1"></div>
      </div>

    </div>

    <div class="ui-border-line" style="height: 10px"></div>

    <div class="profile-teamDesc">
      <h4>멤버십 이용 및 해지 안내</h4>
      <p class="ui-p-indent">- 멤버십 혜택은 결제 즉시 제공됩니다.</p>
      <p class="ui-p-indent">- 본 상품은 처음 결제한 날짜에 매월 자동으로 결제됩니다.</p>
      <p class="ui-p-indent">- 본 상품은 선불 개념이므로, 해지 시 다음 달 자동결제가 중지 됩니다. (일할계산 되지 않음)</p>
      <p class="ui-p-indent">- 해지 시 멤버십 이용 만료일 전까지는 계속 이용 가능합니다.</p>
    </div>
    <div class="ui-border-line" style="height: 10px"></div>

    <left-check-box
      class="ui-mt-2 ui-mb-2"
      text="위 구매조건 확인 및 결제 진행 동의"
      :value="agreeCheck"
      @click="result => {
        agreeCheck = result.checked
        result.update()
      }"
      />
    <div class="ui-border-line" style="height: 10px"></div>

    <form ref="ini2" method="post" :action="paymentIni2.actionUrl" :accept-charset="paymentIni2.acceptCharset" hidden>
      <input type="hidden" name="mid" :value="paymentIni2.form.mid" />
      <input type="hidden" name="orderid" :value="paymentIni2.form.orderid" />
      <input type="hidden" name="price" :value="paymentIni2.form.price" />
      <input type="hidden" name="buyername" :value="paymentIni2.form.buyername" />
      <input type="hidden" name="p_noti" :value="paymentIni2.form.p_noti" />
      <input type="hidden" name="goodname" :value="paymentIni2.form.goodname" />
      <input type="hidden" name="timestamp" :value="paymentIni2.form.timestamp" />
      <input type="hidden" name="period" :value="paymentIni2.form.period" />
      <input type="hidden" name="hashdata" :value="paymentIni2.form.hashdata" />
      <input type="hidden" name="returnurl" :value="paymentIni2.form.returnurl" />
    </form>

    <footer-box
      :submit-text="'결제하기 >'"
      :submit-cb="() => paymentIni2Run()"
      :submit-disabled="!agreeCheck"
    >
    </footer-box>

  </div>
</template>

<script>
import Icon from '@/components/common/Icon'
import FooterBox from '@/components/common/FooterBox'
import LeftCheckBox from '@/components/common/LeftCheckBox'

export default {
  name: 'MembershipJoin',
  components: {
    Icon,
    FooterBox,
    LeftCheckBox,
  },
  data() {
    const artistData = this.$store.state.user.artistData
    const API_URL = this.$lib.API_URL

    return {
      artistData: artistData || {},
      agreeCheck: false,
      paymentIni2: {
        actionUrl: 'https://inilite.inicis.com/inibill/inibill_card.jsp',
        acceptCharset: 'UTF-8',
        form: {
          mid: '',
          orderid: '',
          price: '',
          buyername: 'user 이름',
          p_noti: '회원고유번호|금액',
          goodname: '멤버십 정기결제',
          timestamp: '',
          period: '',
          hashdata: '',
          returnurl: `${API_URL}/web/payment3`,
        },
      },
    }
  },
  computed: {
    userData() {
      return this.$store.state.user.userData
    },
    isMember() {
      // if (this.artistData && Number(this.artistData.memberDate) > 0 && Number(this.artistData.memberDate) * 1000 < Date.now()) {
      //   return true
      // }
      return false
    },
    billData() {
      return this.$store.state.billData || {}
    },
    promoEndDate() {
      return Number(this.billData.promoEndDate || 0) * 1000
    },
    promoSalePer() {
      if (this.promoEndDate >= Date.now()) return Number(this.billData.promoSalePer || 0)
      return 0
    },
    promoPrice() {
      return this.billData.promoPrice
      // let billPrice = Number(this.billData.billPrice || 0)
      // return Math.floor(billPrice / (100 - this.promoSalePer) * 100)
    },
  },
  created() {
    if (this.userData.category === 'client') {
      this.$router.back()
      return
    }

    if (this.isMember) {
      this.$router.replace('/membership')
      return
    }
  },
  methods: {
    // 정기결제
    paymentIni2Run() {
      const order_form = this.$refs.ini2

      let now = this.moment()
      let add30 = now.add(this.billData.billDays || 30, 'd')
      let period = now.format('YYYYMMDD') + add30.format('YYYYMMDD')

      this.paymentIni2.form.buyername = this.userData.userName
      this.paymentIni2.form.p_noti = `${this.userData.useridx}|${this.paymentIni2.form.price}`

      this.paymentIni2.form.mid = this.billData.billMid
      this.paymentIni2.form.orderid = this.billData.billOrderid
      this.paymentIni2.form.price = this.billData.billPrice
      this.paymentIni2.form.timestamp = this.billData.billTimestamp
      this.paymentIni2.form.period = period
      this.paymentIni2.form.hashdata = this.billData.billHashData

      this.$historyStack.set([
        `/membership?p_msg=결제가 취소되었습니다.`,
      ])

      this.$nextTick().then(() => {
        order_form.submit()
      })
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/constant.scss';

.payment-header {
  background-color: $color-deepLavender;
  color: #fff;
  text-align: center;
  margin: 0 -3rem;
  overflow: hidden;
  .title {
    margin: 6rem 0 3rem 0;
    font-size: 3.2rem;
    font-weight: 600;
  }
  .text {
    margin: 3rem 0 6rem 0;
    font-size: 2rem;
    line-height: 1.5;
  }
  .image {
    div {
      margin: 0 auto;
    }
  }
}
.artist-image {
  display: block;
  float: left;
  width: 5rem;
  height: 5rem;
  border-radius: 50%;
  margin-top: -1rem;
  margin-bottom: -0.6rem;
  margin-right: 1rem;
}
#page > .container.profile {
  .profile-info .info-block ul li {
    &:first-child {
      width: auto;
      color: #464646;
    }
    &:nth-child(2) {
      font-weight: 500;
    }
  }
}
</style>
